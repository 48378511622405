const PURPLE = '#382F76'
const BLUE = '#95C1AB'

const WHITE = '#ffffff'
const BLACK = '#000000'

export default {
  PURPLE,
  BLUE,
  WHITE,
  BLACK
}
